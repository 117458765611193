.heading {
  margin-top: 80px;
}
.title {
  margin-bottom: 50px;
  position: relative;
  font-size: 40px;
  font-weight: 600;
  color: #2b3940;
}
.title:after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 50px;
  left: 0;
  right: 0;
  top: 60px;
  margin: 0 auto;
  border-bottom: 2px solid #00af73;
}
/* Header */
* {
  font-family: "Open Sans", sans-serif;
}
@media (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}
@media (max-width: 991px) {
  .container {
    max-width: 100%;
  }
}
body {
  overflow-x: hidden;
  margin: 0 auto;
}
p,
a,
a:hover {
  color: #757575;
}
.header {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background-color: #e2e2e2;
}
.navbar {
  max-width: 1355px;
  margin: 0 auto;
  padding: 15px;
  vertical-align: middle;
}
.navbar-light .navbar-nav .nav-link {
  color: #000000;
  margin-right: 73px;
  margin-left: 0;
  padding: 0;
  letter-spacing: 1px;
  position: relative;
  font-size: 16px;
  text-transform: uppercase;
  opacity: 100;
  font-weight: 600;
  text-decoration: none;
}
.navbar-light .navbar-nav .nav-link:hover {
  color: rgb(0, 176, 116);
}
.navbar-light .navbar-nav .nav-link::before {
  background-color: rgb(0, 176, 116);
  left: 0;
  right: 0;
  height: 0;
  width: 1px;
  content: "";
  position: absolute;
  bottom: -10px;
  transition: 0.3s ease;
  margin: 0 auto;
}
.navbar-light .navbar-nav .nav-link:hover::before {
  height: 10px;
}

@media screen and (max-width: 991px) {
  .nav-menu {
    background: rgb(255, 255, 255);
    padding: 15px;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 64px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }

  .navbar-light .navbar-nav .nav-link:hover::before {
    height: 15px;
  }
  .navbar-light .navbar-nav .nav-link::before {
    background-color: rgb(0, 176, 116);
    left: 0;
    right: 451px;
    width: 1px;
    content: "";
    position: absolute;
    bottom: 11px;
    transition: 0.3s ease;
    margin: 0 auto;
  }

  .navbar-light .navbar-toggler {
    color: none !important;
    border-color: transparent;
  }

  .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 0;
  }
}

/* ----------------------- Banner Text ------------------------ */
.img-bnr-text {
  position: absolute;
  top: 28%;
  margin: 0 130px;
  overflow: hidden;
}
.banner-img {
  position: relative;
  aspect-ratio: attr(width) / attr(height);
}
.img-bnr-text .job-title {
  font-size: 24px;
  color: #4d4d4d;
  font-weight: 500;
  margin: 0;
}
.img-bnr-text h1 {
  font-size: 50px;
  text-transform: capitalize;
  font-weight: 700;
  margin-bottom: 15px;
  margin-top: 10px;
  position: relative;
}
.img-bnr-text p {
  margin: 0;
  color: #4d4d4d;
  line-height: 25px;
  font-size: 16px;
}

.img-bnr-text .BtnView {
  min-width: 140px;
  min-height: 46px;
}

.BtnView {
  margin-top: 40px;
  text-decoration: none;
  background-color: #000;
  min-width: 160px;
  min-height: 46px;
  color: #fff;
  fill: #fff;
  border: none;
  font-size: 14px;
  font-weight: 100;
  border-radius: 0;
  transform: perspective(1px) translateZ(0);
  position: relative;
  transition-property: color;
  transition-duration: 0.3s;
  transition: all 0.5s ease;
  vertical-align: middle;
  line-height: 0;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.BtnView:hover::before {
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
}
.BtnView::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #00af73;
  border-radius: 0;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.BtnView:hover {
  background-color: #000;
  color: #fff;
}
/* ------------------------ About us ------------------------ */
.about-list {
  line-height: 40px;
}
.about-img {
  background-color: white;
  margin-top: 77px;
}
.rounded-circle {
  border: 7px solid #d5d7d9;
  text-align: center;
  margin: 0 0 !important;
  aspect-ratio: attr(width) / attr(height);
}
.about-name h2 {
  font-size: 30px;
  font-weight: 600;
  margin-top: 10px;
}
.about-name p {
  font-size: 16px;
  opacity: 69%;
  font-weight: 600;
  margin: 0 auto;
}

.about-title {
  position: relative;
  font-size: 40px;
  margin-bottom: 50px;
  font-weight: 600;
  color: #2b3940;
}

.about-title:after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 50px;
  left: 0;
  top: 60px;
  margin: 0 auto;
  border-bottom: 2px solid #00af73;
}

.about-subtitle {
  font-size: 16px;
  line-height: 1.375;
  text-align: left;
  letter-spacing: 0.5px;
}

.about-sub-info {
  font-size: 24px;
  margin-top: 34px;
  font-weight: 600;
}
.perName1 {
  font-size: 16px;
  color: #2b3940;
  font-weight: 700;
  margin: 0;
}
.perName2 {
  font-size: 16px;
  color: #595959;
  font-weight: 500;
}
/* ------------------------  Experience ------------------------ */
.exp-main {
  background-color: #2b3940;
  padding-top: 40px;
  padding-bottom: 40px;
}
.exp-main .title {
  margin-bottom: 30px;
}
.exp-main h2 {
  color: #fff;
  margin-bottom: 50px;
  position: relative;
  font-size: 40px;
  font-weight: 600;
}
hr {
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.1;
}
.card {
  border: none;
  border-radius: 0px;
}
.exp-card {
  border-radius: 0.25rem;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border: 0px;
  margin-right: 5px;
  margin-left: 5px;
}
.exp-card:hover {
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
}
.exp-block {
  margin-top: 18px;
  margin-bottom: 18px;
}
.exp-body {
  padding-right: 25px;
  padding-left: 25px;
  padding-top: 25px;
  padding-bottom: 25px;
}
.exp-text {
  font-size: 16px;
}
.exp-title {
  font-size: 20px;
  font-weight: 700;
}
.exp-title p {
  font-size: 14px;
  font-weight: 500;
  padding-top: 9px;
}
/* ------------------------  Education & Skills ------------------------ */
.educationColBox {
  padding: 0;
}
.education-box-main {
  margin: 0 auto;
}
.education-box-main .col {
  padding: 0;
}
.education .school {
  font-size: 14px;
  text-align: center;
  vertical-align: middle;
  font-family: "Open Sans Light";
  color: rgb(255, 255, 255);
  display: inline-block;
  background-color: rgb(0, 176, 116);
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  margin: 0 auto;
}
.education h3 {
  font-size: 20px;
  margin-top: 15px;
  margin-bottom: 10px;
}
.education .concentration {
  font-size: 14px;
  letter-spacing: 0.5px;
  font-weight: 600;
  margin: 0 auto;
  color: #757575;
}
.education-box {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 25px;
  padding-bottom: 25px;
  margin: 0;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}
.skill-box {
  align-items: center;
  padding: 15px;
}
.skill-bar {
  background: #fff;
  text-align: left;
}
.skill-box span {
  font-size: 30px;
  font-weight: 600;
}
.skill-bar .bar {
  margin: 30px 30px 15px;
}
.skill-bar .bar:first-child {
  margin-top: 0px;
}
.skill-bar .bar .info {
  margin-bottom: 2px;
}
.skill-bar .bar .info span {
  font-weight: 500;
  font-size: 17px;
}
.skill-bar .bar .progress-line {
  height: 10px;
  width: 100%;
  background: #f0f0f0;
  position: relative;
  transform-origin: left;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05),
    0 1px rgba(255, 255, 255, 0.8);
}
.bar .progress-line span {
  height: 100%;
  position: absolute;
  width: 80%;
  background: #00af73;
}
.bar .progress-line.width90 span {
  width: 90%;
}
.bar .progress-line.width85 span {
  width: 85%;
}
.bar .progress-line.width80 span {
  width: 80%;
}
.bar .progress-line.width75 span {
  width: 75%;
}
.bar .progress-line.width70 span {
  width: 70%;
}
/* ------------------------------------  Footer ------------------------------------ */
.send-btn {
  border: 1px solid #00b074;
  width: 199.99px;
  height: 50px;
  background-color: transparent;
  letter-spacing: 1px;
  margin-top: 40px;
  color: #00b074;
  font-size: 14px;
  font-weight: 300;
  border-radius: 0;
  transition-duration: 0.3s;
  transition: all 0.5s ease;
  vertical-align: middle;
}
.send-btn:hover {
  background-color: #00af73;
  color: #fff;
}
.f-left {
  color: #fff;
  line-height: 14px;
}
.f-left p {
  color: #fff;
}
.f-contact {
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 30px;
  text-align: left;
  position: relative;
}

.f-contact:after {
  content: "";
  position: absolute;
  top: 60px;
  width: 50px;
  left: 7px;
  margin: 0 auto;
  border-bottom: 2px solid #00af73;
}
.f-icon {
  margin-bottom: 30px;
}
.footer {
  background-color: #2b3940;
  padding-top: 80px;
  padding-bottom: 80px;
}
.f-left {
  color: #fff;
  line-height: 14px;
}
.f-content {
  text-align: left;
  position: relative;
}
.f-icon i {
  background: #00af73;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  vertical-align: middle;
  padding: 10px;
  display: inline-flex;
}

.f-icon:hover i {
  background-color: #000;
}

.f-icon a {
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: 300;
  line-height: 2;
  text-decoration: none;
  color: white;
}
.f-end {
  text-align: end;
}
.form-control:focus {
  box-shadow: none;
}
.inputbox {
  background-color: #fdfdfd;
  border: none;
  width: 100%;
  height: 50px;
  font-size: 15px;
  padding-left: 10px;
  vertical-align: middle;
}
.inputboxmessage {
  width: 100%;
  height: 165px;
  overflow: hidden;
}
.form-control {
  border-radius: 0px !important;
}
.f-link {
  text-align: center;
  background-color: #00b074;
  font-size: 14px;
  padding: 17px;
  height: 50px;
  color: #fff;
}
.icon {
  float: left;
  margin-right: 10px;
}
/* ------------------------------------ End Footer ------------------------------------ */

/* scroll-button */
.scroll-to-top {
  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;
  animation: fadeIn 700ms ease-in-out 1s both;
  cursor: pointer;
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 10%);
  z-index: 1;
}
.scroll-icon:hover {
  background-color: #000;
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 20%);
}
.scroll-icon {
  background-color: #00b074;
  padding: 12px;
  color: #fff;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .navbar {
    padding-left: 50px;
    padding-right: 50px;
  }
  .navbar-light .navbar-nav .nav-link {
    font-size: 14px;
  }
  .font {
    margin-right: 35px;
  }
  .img-bnr-text {
    vertical-align: middle;
  }
  .img-bnr-text h5 {
    font-size: 20px;
  }
  .img-bnr-text h1 {
    font-size: 30px;
  }
  .img-bnr-text p {
    font-size: 14px;
  }
  .img-bnr-text {
    top: 20%;
  }
  .main-service {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .service h1 {
    margin-bottom: 50px;
  }
  .heading {
    margin-top: 30px;
  }
  .exp-card {
    margin-right: 5px;
  }
  .skill-box span {
    font-size: 25px;
  }
  .footer {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .f-icon span {
    font-size: 15px;
  }
}
@media (min-width: 1200px) {
  .navbar-light .navbar-nav .nav-link {
    font-size: 15px;
  }
}
@media (max-width: 991px) {
  .nav-menu {
    background: rgb(255, 255, 255);
  }
  .navbar-expand .navbar-nav {
    display: block;
  }
  .navbar-expand .navbar-toggler {
    display: block;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .navbar-light .navbar-nav .nav-link {
    margin-left: 0;
    margin-right: 0;
    padding-bottom: 0;
    line-height: 35px;
  }
  .img-bnr-text {
    top: 50px;
    margin-left: 30px;
  }
  .img-bnr-text h5 {
    font-size: 24px;
  }
  .img-bnr-text h1 {
    font-size: 34px;
  }
  .img-bnr-text p {
    font-size: 14px;
  }
  .BtnView {
    min-width: 120px;
    min-height: 40px;
    margin-top: 15px;
  }
  .heading {
    margin-top: 40px;
  }
  .perName2,
  .perName1,
  .about-subtitle {
    font-size: 13px;
    text-align: justify;
  }
  .main-service {
    padding-top: 40px;
  }
  .cardtitle {
    padding-bottom: 10px;
  }
  .exp-block {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .exp-card {
    margin-left: 5px;
    margin-right: 5px;
  }
  .skill-box span {
    font-size: 22px;
  }
  .textblock {
    margin-left: 30px;
    margin-right: 30px;
  }
  .textblock1 {
    padding: 20px 25px;
    margin: -69px 38px 35px 38px;
  }
  .textblock1 h1 {
    font-size: 15px;
  }
  .footer {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .send-btn {
    margin-top: 20px;
  }
  .f-icon {
    margin-bottom: 15px;
  }
  .f-contact {
    font-size: 35px;
    margin-bottom: 15px;
  }
  .f-contact:after {
    top: 45px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .img-bnr-text {
    top: 20%;
    margin-left: 40px;
  }
  .img-bnr-text h1 {
    font-size: 35px;
  }
  .img-bnr-text p {
    display: none;
  }
  .BtnView {
    margin-top: 1px;
    min-width: 100px;
    min-height: 50px;
  }
  .rounded-circle {
    max-width: 50%;
  }
  .cardtitle {
    font-size: 18px;
  }
  .about-title {
    margin-bottom: 25px;
    font-size: 35px;
  }
  .about-title:after {
    top: 40px;
  }
  .about-img {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .BtnView {
    margin-top: 15px;
  }
  .educationSkill .title {
    margin-bottom: 30px;
  }
  .education {
    text-align: center;
  }
  .education-box {
    margin-left: 100px;
    margin-right: 100px;
  }
  hr {
    margin: 15px;
  }
  .skill-bar {
    margin-top: 10px;
  }
  .skill-box {
    align-items: center;
    padding: 10px;
  }
  .skill-box span {
    font-size: 20px;
  }
  .education-box span {
    font-size: 20px;
    margin-right: 55px;
  }
  .heading {
    margin-top: 20px;
  }
  .f-content {
    font-size: 35px;
  }
  .f-contact {
    font-size: 35px;
  }
  .f-contact:after {
    top: 50px;
  }
  .footer {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
@media (min-width: 451px) and (max-width: 575px) {
  .img-bnr-text {
    top: 15%;
    margin-left: 15px;
  }
  .img-bnr-text h1 {
    font-size: 30px;
  }
  .img-bnr-text p {
    display: none;
  }
  .BtnView {
    margin-top: 8px;
    min-width: 112px;
    min-height: 40px;
  }
  .rounded-circle {
    max-width: 50%;
  }
  .about-img {
    margin-top: 5px;
    margin-bottom: 28px;
    margin-left: 30px;
    margin-right: 30px;
  }
  .about-img h6 {
    font-size: 24px;
  }
  .about-img p {
    font-size: 14px;
  }
  .about-title {
    font-size: 35px;
    margin-bottom: 20px;
  }
  .about-title:after {
    top: 50px;
  }
  .perName2,
  .perName1,
  .about-subtitle {
    font-size: 14px;
  }
  .cardtitle {
    font-size: 19px;
    padding-bottom: 5px;
    margin-top: -25px;
  }
  .heading {
    margin-top: 30px;
  }
  .exp-title {
    font-size: 22px;
  }
  .exp-title p {
    font-size: 15px;
  }
  .exp-text {
    font-size: 15px;
  }
  .education {
    text-align: center;
  }
  .education-box {
    margin-left: 35px;
    margin-right: 35px;
  }
  .education-box span {
    font-size: 20px;
  }
  .skill-bar {
    margin-top: 10px;
  }
  .skill-box {
    align-items: center;
    padding: 10px;
  }
  .skill-box span {
    font-size: 20px;
  }
  hr {
    margin: 7px;
  }
  .f-icon span {
    font-size: 16px;
  }
  .footer {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .navbar-light .navbar-nav .nav-link {
    margin-left: 0;
    margin-right: 0;
    padding-bottom: 0;
    line-height: 35px;
    z-index: 3;
  }
  .service-title:after {
    top: 45px;
  }
  .service h1 {
    font-size: 35px;
    margin-bottom: 30px;
  }
  .f-contact {
    font-size: 30px;
  }
  .f-contact:after {
    top: 45px;
  }
  .f-icon i {
    padding: 8px;
  }
  .f-icon {
    margin-bottom: 18px;
  }
  .f-icon span {
    font-size: 16px;
  }
  .inputbox {
    font-size: 14px;
  }
  .inputboxmessage {
    height: 135px;
    padding-bottom: 100px;
    font-size: 15px;
    padding-left: 10px;
    margin: 5px 0;
  }
  .send-btn {
    max-height: 45px;
    max-width: 150px;
    margin-top: 20px;
    margin: 5px;
  }
  .img-bnr-text .BtnView {
    min-height: 40px;
    min-width: 80px;
  }
  .resumeButton {
    margin: 0 auto;
    display: block;
  }
  .f-icon a {
    font-size: 16px;
  }
}
@media (min-width: 451px) and (max-width: 767px) {
  .exp-main .title {
    margin-top: 40px;
    margin-bottom: 17px;
    font-size: 35px;
  }
  .title:after {
    top: 45px;
  }
  .title {
    font-size: 35px;
    margin-bottom: 30px;
  }
}

@media (min-width: 320px) and (max-width: 450px) {
  .navbar-light .navbar-nav .nav-link {
    margin-left: 0;
    margin-right: 0;
    padding-bottom: 0;
    line-height: 35px;
  }
  .img-bnr-text h5 {
    font-size: 14px;
  }
  .img-bnr-text h1 {
    font-size: 24px;
  }
  .img-bnr-text {
    top: 12%;
    margin: 0 15px;
  }
  .img-bnr-text p {
    display: none;
  }
  .BtnView {
    margin-top: 0;
    min-width: 100px;
    min-height: 30px;
    font-size: 14px;
  }
  .about-img {
    margin-top: 0px;
    margin-bottom: 5px;
    margin-left: 30px;
    margin-right: 30px;
  }
  .about-name p {
    font-size: 14px;
    margin-top: -5px;
  }
  .about-title {
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 22px;
  }
  .about-title:after {
    top: 30px;
  }
  .about-subtitle {
    font-size: 14px;
  }
  .about-sub-info {
    font-size: 20px;
    margin-top: 2px;
  }
  .about-list {
    line-height: 22px;
  }
  .abouttextbox .BtnView {
    margin-top: 15px;
  }
  .perName1,
  .perName2 {
    font-size: 14px;
  }
  .img--size {
    vertical-align: middle;
  }
  .title {
    font-size: 22px;
    margin-bottom: 10px;
  }
  .title:after {
    top: 30px;
  }
  hr {
    margin: 7px;
  }
  .exp-main .title {
    margin-bottom: 18px;
  }
  .exp-title {
    font-size: 18px;
    font-weight: 600;
  }
  .exp-text {
    font-size: 14px;
  }
  .exp-body {
    padding: 10px;
  }
  .exp-block {
    margin: 10px 10px;
  }
  .education-box hr {
    margin: 12px;
  }
  .education-box {
    margin-bottom: 15px;
    margin-top: 15px;
  }
  .skill-bar span {
    font-size: 18px;
  }
  .skill-box {
    padding: 5px;
  }
  .heading {
    margin-top: 20px;
  }
  .f-contact {
    font-size: 25px;
  }
  .f-contact:after {
    top: 45px;
  }
  .f-icon {
    margin-bottom: 16px;
  }
  .f-icon span {
    font-size: 15px;
  }
  .send-btn {
    max-height: 45px;
    max-width: 150px;
    margin-top: 20px;
  }
  .footer {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

/* ------------------------------------ Accessibility ------------------------------------ */
button:focus-visible {
  /* remove default focus style */
  outline: none;
  /* custom focus styles */
  box-shadow: 0 0 2px 2px #00b074;
  color: #00b074;
}
.skipLink {
  background-color: #00b074;
  color: #fff;
  left: 5px;
  padding: 10px;
  position: absolute;
  text-decoration: none;
  top: 5px;
  transform: translateX(-110%);
  transition: transform 0.4s;
  z-index: 10;
}
.skipLink:hover {
  color: #fff;
}
.skipLink:focus {
  transform: translateX(0%);
}
.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
